<template>
  <div>
    <v-row class="match-height">
      <v-col
        cols="12"
        md="6"
        class="align-self-end"
      >
        <welcome></welcome>
      </v-col>
      <v-col
        cols="6"
        md="3"
        class="align-self-end"
      >
        <statistics-card-vertical
          :change="adhesionStats.change"
          :color="adhesionStats.color"
          :icon="adhesionStats.icon"
          :statistics="adhesionStats.statistics"
          :stat-title="adhesionStats.statTitle"
          :subtitle="adhesionStats.subtitle"
          :title="adhesionStats.title"
        ></statistics-card-vertical>
      </v-col>
      <v-col
        cols="6"
        md="3"
        class="align-self-end"
      >
        <statistics-card-vertical
          :change="cotisationStats.change"
          :color="cotisationStats.color"
          :icon="cotisationStats.icon"
          :statistics="cotisationStats.statistics"
          :stat-title="cotisationStats.statTitle"
          :subtitle="cotisationStats.subtitle"
          :title="cotisationStats.title"
        ></statistics-card-vertical>
      </v-col>
      <v-col
        cols="12"
        md="6"
        order="1"
      >
        <adhesion-hebdo></adhesion-hebdo>
      </v-col>
      <v-col
        cols="12"
        md="6"
        order="1"
      >
        <stat-side-pockets></stat-side-pockets>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <adherents-list></adherents-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import axios from '@axios'
import { mdiTrendingUp, mdiCurrencyEur } from '@mdi/js'
import adhesionHebdo from './application/dashboard/AdhesionsHebdo.vue'
import statisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'
import statSidePockets from './application/dashboard/StatSidePockets.vue'
import welcome from './application/dashboard/Welcome.vue'
import adherentsList from './application/dashboard/AdherentsList.vue'
import { currencyFormat } from '../@core/utils'

export default {
  components: {
    welcome,
    statisticsCardVertical,
    adhesionHebdo,
    statSidePockets,
    adherentsList,
  },

  setup() {
    const adhesionStats = ref({
      title: 'Adhérents',
      statTitle: 'Adhésions effectives :',
      icon: mdiTrendingUp,
      color: 'info',
      subtitle: 'Inscrits sur le site.',
      statistics: '',
    })
    const cotisationStats = ref({
      title: 'Cotisations',
      statTitle: 'Montant total :',
      icon: mdiCurrencyEur,
      color: 'success',
      subtitle: 'Cumul des cotisations.',
      statistics: '',
    })

    onMounted(async () => {
      await axios.get('adhesions/stats').then(response => {
        adhesionStats.value.statistics = response.data.adhesions.toString()
        cotisationStats.value.statistics = currencyFormat(response.data.cotisations)
      })
    })

    return {
      adhesionStats,
      cotisationStats,
    }
  },
}
</script>
