<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Préjudices adhérents</span>
    </v-card-title>

    <v-card-text class="my-5">
      <div class="d-flex">
        <h1 class="text-4xl font-weight-semibold">
          {{ totalSp }}
        </h1>
      </div>
      <h4 class="mt-2 font-weight-medium">
        Préjudice total estimé pour tous les adhérents
      </h4>
    </v-card-text>

    <v-card-text>
      <div
        v-for="(earning,index) in statSp"
        :key="earning.avatar"
        :class="`d-flex align-start ${index >0 ? 'mt-8':''}`"
      >
        <v-list-item-avatar
          class="v-avatar-light-bg justify-center"
          :class="earning.color + '--text'"
          size="40"
          rounded
        >
          <v-icon
            size="30"
            :color="earning.color"
          >
            {{ mdiTrendingDown }}
          </v-icon>
        </v-list-item-avatar>

        <div class="d-flex flex-grow-1">
          <div>
            <h4 class="font-weight-medium">
              {{ earning.title }}
            </h4>
            <span class="text-xs text-no-wrap">{{ earning.subtitle }}</span>
          </div>

          <v-spacer></v-spacer>

          <div class="ms-1">
            <p class="text--primary font-weight-medium mb-1">
              {{ earning.earning }}
            </p>
            <v-progress-linear
              :value="earning.progress"
              :color="earning.color"
            ></v-progress-linear>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '@axios'
import { mdiTrendingDown } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { currencyFormat } from '../../../@core/utils'

export default {
  setup() {
    const totalSp = ref(0)
    const statSp = ref([])

    const percent = (total, price) => Math.round((price * 100) / total)
    const colors = ['error', 'primary', 'info', 'success']

    const modesDetention = [
      '',
      'Contrat d\'assurance vie',
      'Compte titre',
    ]

    onMounted(async () => {
      await axios.get('sidepockets/stats').then(response => {
        const total = response.data.map(e => e.total).reduce((prev, next) => prev + next)
        const sidepockets = response.data.sort((a, b) => b.total - a.total)
        totalSp.value = currencyFormat(total)
        sidepockets.forEach((sp, i) => {
          statSp.value.push({
            title: modesDetention[sp.mode_detention_id],
            subtitle: `${sp.contrats} contrats au total`,
            earning: currencyFormat(sp.total),
            progress: percent(total, sp.total).toString(),
            color: colors[i],
          })
        })
      })
    })

    return {
      statSp,
      totalSp,
      mdiTrendingDown,
      modesDetention,
    }
  },
}
</script>
