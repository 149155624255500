<template>
  <v-card>
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        sm="9"
        class="align-self-end"
      >
        <v-card-title class="greeting-title d-flex flex-nowrap text-2xl">
          <span class="text-no-wrap">Bonjour</span>
          <span class="text-no-wrap font-weight-bold mx-1">{{ name }} !</span>
        </v-card-title>

        <v-card-text>
          <span>Bienvenue sur l'administration du Collectif Porteurs H2O.</span><br />
          <span class="text--primary font-weight-semibold">C'est ici que vous allez pouvoir gérer les données des adhérents de l'association 🤩.</span>
        </v-card-text>
      </v-col>
      <v-col
        cols="4"
        sm="3"
      >
        <div>
          <v-img
            width="121"
            src="@/assets/images/misc/tableau-de-bord-mobile.png"
            class="img-tableau-de-bord"
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  setup() {
    const { name } = JSON.parse(sessionStorage.getItem('userData'))

    return { name }
  },
}
</script>

<style lang="scss" scoped>
.gamification-tree-4,
.img-tableau-de-bord,
.gamification-tree {
  position: absolute;
}
.img-tableau-de-bord {
  bottom: 0;
  right: 5%;
}

@media (max-width: 768px) {
  .img-tableau-de-bord {
    display: none;
  }
}

@media (max-width: 400px) {
  .greeting-title {
    font-size: 1.2rem !important;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .img-tableau-de-bord {
      right: initial;
      left: 5%;
    }
  }
}
</style>
