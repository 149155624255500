<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Derniers adhérents inscrits</span>
    </v-card-title>
    <v-data-table
      :headers="tableColumnHeaders"
      :items="adherentsListData"
      item-key="id"
      hide-default-footer
      class="table-rounded"
      :loading="loading"
      locale="fr-FR"
      no-data-text="Aucune donnée"
      loading-text="Chargement des données..."
    >
      <!-- name -->
      <template #[`item.fullName`]="{item}">
        <div class="d-flex align-center">
          <v-avatar
            color="primary"
            class="v-avatar-light-bg"
            :class="setColor()"
            size="32"
          >
            <span
              class="font-weight-medium"
            >{{ item.initiales }}</span>
          </v-avatar>

          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary  font-weight-semibold text-truncate text-capitalize">{{ item.fullName }}</span>
          </div>
        </div>
      </template>

      <!-- status -->
      <template #[`item.statut`]="{item}">
        <div class="d-flex align-center">
          <v-avatar
            size="30"
            :color="statut[item.type].color"
            :class="`v-avatar-light-bg ${statut[item.type].color}--text me-3`"
          >
            <v-icon
              size="20"
              :color="statut[item.type].color"
            >
              {{ statut[item.type].icon }}
            </v-icon>
          </v-avatar>
          {{ item.statut }}
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from '@axios'
import { mdiAccountOutline, mdiDomain } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { currencyFormat, dateFormat } from '../../../@core/utils'

export default {
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      inactive: 'secondary',
      active: 'success',
      pending: 'warning',
      applied: 'info',
      /* eslint-enable key-spacing */
    }

    const statut = {
      particulier: { icon: mdiAccountOutline, color: 'info' },
      societe: { icon: mdiDomain, color: 'success' },
    }

    const colors = ['primary', 'secondary', 'success', 'warning', 'info', 'error']

    const adherentsListData = ref([])
    const loading = ref(true)

    const tableColumnHeaders = [
      { text: 'ADHÉRENT', value: 'fullName' },
      { text: 'E-MAIL', value: 'email' },
      { text: 'COTISATION', value: 'cotisation', align: 'center' },
      { text: "DATE D'INSCRIPTION", value: 'date_inscription', align: 'center' },
      { text: 'STATUT', value: 'statut', align: 'center' },
    ]

    const setColor = () => `${colors[Math.floor(Math.random() * 6)]}--text`

    onMounted(async () => {
      await axios('adhesions/last/7').then(response => {
        let statutAdherent = '?'
        let fullName = ''
        let initiales = ''
        response.data.forEach(adhesion => {
          if (adhesion.adherent.statut === 'societe') {
            const societe = adhesion.adherent.societe !== null ? adhesion.adherent.societe : '?'
            fullName = societe.toLowerCase()
            statutAdherent = 'Personne morale'
            initiales = societe.slice(0, 2).toUpperCase()
          } else {
            const nom = adhesion.adherent.nom !== null ? adhesion.adherent.nom : '?'
            const prenom = adhesion.adherent.prenom !== null ? adhesion.adherent.prenom : '?'
            fullName = `${nom} ${prenom}`.toLowerCase()
            statutAdherent = 'Personne physique'
            initiales = nom.slice(0, 1).toUpperCase() + prenom.slice(0, 1).toUpperCase()
          }
          adherentsListData.value.push({
            id: adhesion.id,
            fullName,
            initiales,
            email: adhesion.adherent.email,
            cotisation: currencyFormat(adhesion.cotisation),
            statut: statutAdherent,
            type: adhesion.adherent.statut,
            date_inscription: dateFormat(adhesion.created_at),
          })
        })
        loading.value = false
      })
    })

    return {
      adherentsListData,
      statusColor,
      statut,
      tableColumnHeaders,
      setColor,
      loading,
    }
  },
}
</script>
