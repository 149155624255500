<template>
  <v-card>
    <v-card-title class="align-start">
      <div>
        <p class="mb-2">
          Dernières adhésions
        </p>
        <small class="text--secondary text-base">Inscriptions depuis le {{ firstDay }}</small>
      </div>
    </v-card-title>
    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        height="250"
        :options="chartOptions"
        :series="chartData"
      ></vue-apex-charts>

      <!-- Stats -->
      <div class="d-flex justify-space-around flex-wrap mt-5">
        <div class="d-flex">
          <v-avatar
            class="v-avatar-light-bg primary--text"
            rounded
            size="40"
          >
            <v-icon
              size="30"
              color="primary"
            >
              {{ icons.mdiTrendingUp }}
            </v-icon>
          </v-avatar>
          <div class="ms-4 d-flex flex-column">
            <p class="text--primary font-weight-semibold text-base mb-0">
              {{ totalAdhesion }}
            </p>
            <span>Nouveaux adhérents</span>
          </div>
        </div>

        <div class="d-flex">
          <v-avatar
            class="v-avatar-light-bg success--text"
            rounded
            size="40"
          >
            <v-icon
              size="30"
              color="success"
            >
              {{ icons.mdiCurrencyEur }}
            </v-icon>
          </v-avatar>
          <div class="ms-4 d-flex flex-column">
            <p class="text--primary font-weight-semibold text-base mb-0">
              {{ totalCotisation }} €
            </p>
            <span>de cotisations</span>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, onMounted } from '@vue/composition-api'
import axios from '@axios'
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyEur } from '@mdi/js'

import { getVuetify, addAlpha } from '@core/utils'

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  setup() {
    const $vuetify = getVuetify()
    const totalAdhesion = ref(0)
    const totalCotisation = ref(0)
    const firstDay = ref(null)

    const chartOptions = ref({
      colors: [
        addAlpha($vuetify.theme.currentTheme.primary, 0.1),
        addAlpha($vuetify.theme.currentTheme.primary, 0.1),
        addAlpha($vuetify.theme.currentTheme.primary, 0.1),
        addAlpha($vuetify.theme.currentTheme.primary, 0.1),
        addAlpha($vuetify.theme.currentTheme.primary, 0.1),
        addAlpha($vuetify.theme.currentTheme.primary, 0.1),
        addAlpha($vuetify.theme.currentTheme.primary, 0.1),
      ],
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '60%',
          distributed: true,
          borderRadius: 4,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tickPlacement: 'on',
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: true,
      },
      grid: {
        show: false,
        padding: {
          top: -20,
          left: -10,
          right: -10,
        },
      },
    })

    const chartData = ref([
      {
        data: [],
      },
    ])

    const formatDate = (date, small = true) => {
      const el = date.split('-')

      return `${el[2]}/${el[1]}${!small ? `/${el[0]}` : ''}`
    }

    onMounted(async () => {
      await axios.get('adhesions/latest').then(response => {
        const resp = response.data.reverse()
        const categories = []
        const data = []
        let calcTotalAdhesions = 0
        let calcTotalCotisation = 0
        let higherIndex = 0
        resp.forEach((adhesion, i) => {
          const total = +adhesion.total
          if (+resp[higherIndex].total < total) {
            higherIndex = i
          }
          categories.push(formatDate(adhesion.date))
          data.push(total)
          calcTotalAdhesions += +adhesion.total
          calcTotalCotisation += +adhesion.cotisations
        })

        const { colors } = chartOptions.value
        colors.splice(higherIndex, 1, $vuetify.theme.currentTheme.primary)

        totalAdhesion.value = calcTotalAdhesions
        totalCotisation.value = calcTotalCotisation
        firstDay.value = formatDate(resp[0].date, false)

        chartOptions.value = {
          ...chartOptions.value,
          ...{
            colors,
            xaxis: {
              categories,
            },
          },
        }

        chartData.value[0] = { data }
      })
    })

    return {
      chartOptions,
      chartData,
      totalAdhesion,
      totalCotisation,
      firstDay,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyEur,
      },
    }
  },
}
</script>
